.App {
  text-align: center;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  min-height: 250px;
  padding: 5% 5%;
}

.App-header div {
  /*margin-top: 25px;*/
}

#root {
  height: 100%;
}

#App-Titles {
  margin-top: 2%;
}

#App-Title-GPT {
  font-weight: bold;
  font-size: 60px;
  color: #4B9F82;

}

#App-Title-vs {
  font-weight: bold;
  font-size: 60px;
  color: #E05F5E;

  @media (pointer:none),
  (pointer:coarse),
  screen and (max-width: 768px) {
    font-size: 48px;
  }
}

#App-Title-Google {
  font-weight: bold;
  font-size: 60px;
  color: #5786EC;
}

@media screen and (max-width: 700px) {

  #App-Title-Google {
    font-size: 24px;
  }

  #App-Title-vs {
    font-size: 24px;
  }

  #App-Title-GPT {
    font-size: 24px;
  }
}


.HeaderLinkPPL {
  display: block;
  position: absolute;
  text-align: left;
  margin: 10px;
  font-size: 14px;
  color: black;
}

.HeaderLinkTutorial {
  display: block;
  position: absolute;
  text-align: right;
  right: 0;
  margin: 10px;
  font-size: 14px;
  color: black;
}

.HeaderLinkPPL a,
.FooterLinkPPL a,
.HeaderLinkTutorial a,
.FooterLinkTutorial a {
  color: #0171E3;
  font-style: normal;
  text-decoration: none;
}

.FooterLinkPPL,
.FooterLinkTutorial {
  display: none;
}

@media screen and (max-width: 700px) {

  .HeaderLinkPPL,
  .HeaderLinkTutorial {
    display: none;
  }

  .FooterLinkPPL {
    display: block;
    font-size: 12px;
    margin: 0 0 0 0;
  }

  .FooterLinkPPL div {
    padding: 20px;
  }

  .FooterLinkTutorial {
    display: block;
    font-size: 12px;
    margin: 0 0 0 0;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#main-title {
  font-family: 'Roboto', sans-serif;
  font-size: 48px;
}

#strapline {
  font-family: 'Roboto Slab', serif;
  font-weight: 500;
  font-size: 24px;
  margin: 15px 0 40px 0;
}

@media screen and (max-width: 700px) {
  #strapline {
    font-family: 'Roboto Slab', serif;
    font-size: 16px;
    margin: 5px 0 20px 0;
  }
}

.Input {
  font-size: 20px;
  font-weight: 400;
  color: gray;
  padding: 15px;
  border-radius: 4px;
  border: 1px solid gainsboro;
  min-width: 250px;
  max-width: 800px;
  width: 50vw;
  margin: 0;
}

.Main-Container {
  height: 100%;
}

.Search-Button-Container {
  margin: 20px 0 0 0;
}

.Search-Button {
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  border: none;
  margin-left: 10px;
  margin-top: 0;
  padding: 10px 20px;
  background-color: #0171E3;
  color: white;
}

.Search-Container {
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  border-top: 1px solid #D9D9D9;
}

.SearchParent {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50vh;
}

.Search1 {
  width: 50%;
  height: 100vh;
  color: black;
  border-right: 1px solid #D9D9D9;
  padding-top: 25px;
}

.Search2 {
  width: 50%;
  height: 100vh;
  color: black;
  padding-top: 25px;
}

.Google-Result-Item {
  text-align: left;
  margin: 0px 50px 26px 50px;
  padding: 0;
  font-size: 16px;
}

@media screen and (max-width: 700px) {
  .Google-Result-Item {
    margin: 0px 10px 10px 10px;
    font-size: 12px;
  }
}

.Google-Result-Item div {
  text-align: left;
}

.Google-Result-Title {
  font-family: Arial, Helvetica, sans-serif;
  font-size: inherit;
  font-weight: 400;
  line-height: 1.65em;
  margin: 0;
  padding: 0;
}

.Google-Result-Title a {
  color: #5786EC;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, .1);
}

.Google-Result-Text {
  font-size: inherit;
  font-weight: normal;
  color: black;
  line-height: 1.65em;
  margin: 0 0 0 0;
  padding: 0;
}

.GPT-Result-Item {
  text-align: left;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: normal;
  color: black;
  line-height: 1.65em;
  margin: 0px 50px 25px 50px;
}

@media (max-width: 700px) {
  .GPT-Result-Item {
    flex-direction: column;
    margin: 0px 10px 25px 10px;
    padding: 0 0 0 0;
    font-size: 12px;
  }
}

.GPT-Result-Item img {
  width: 35px;
  height: 35px;
  margin: 8px 0 0 0;
}

@media screen and (max-width: 700px) {
  .GPT-Result-Item img {
    width: 25px;
    height: 25px;
    margin: 0 0 0 6px;
  }
}

.GPT-Result-Item div {
  padding: 0px 20px;
}

.Search-Error {
  font-weight: bold;
  text-align: center;
  color: red;
}

.Search-Note {
  font-weight: bold;
  text-align: center;
  color: gray;
}

@media screen and (max-width: 700px) {
  .GPT-Result-Item div {
    padding: 0px 10px;
  }
}

.Linkedin-Container {
  margin-top: 20px;
}

.Linkedin-Container iframe {
  border: none;
  width: 100%;
  max-width: 600px;
  min-height: 250px;
  height: 200vh;
}

.Vote-Container {
  margin-top: 30px;
}

@media screen and (max-width: 700px) {
  .Vote-Container {
    font-size: 12px;
    padding: 5px 20px;
  }
}

.Vote-Container a {
  color: #0171E3;
  font-weight: bold;
  text-decoration: none;
  font-size: 16px;
}