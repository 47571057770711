.text-container{
    text-align: left;
}

@keyframes grow {
    0% { max-height: var(--lineHeight); }
    100% { max-height: calc(var(--lineHeight) * var(--lines)); }
  }
  
  @keyframes carriageReturn {
    0% { top: 0; }
    100% { top: calc(var(--lineHeight) * var(--lines)); }
  }
  
  @keyframes type {
    0% { width: 100%; }
    100% { width: 0%; }
  }
  
  @keyframes caret {
    0% { color: var(--bgColor); }
    100% { color: black; }
  }
  
  .typewriter {
    --bgColor: white;
    --lines: 200;
    --lineHeight: 1.65em;
    --timePerLine: 1s;
    --widthCh: 60;
    --width: calc(var(--widthCh) * 1ch);
    /* do not touch the time property!!! */
    --time: calc(var(--lines) * var(--timePerLine));
    animation: grow var(--time) steps(var(--lines));
    animation-fill-mode: forwards;
    background: var(--bgColor);  
    line-height: var(--lineHeight);
    max-height: var(--lineHeight);
    overflow: hidden;
    position: relative;
    width: 100%;
    /* word-break: break-all; */
  }

  @media (max-width: 700px) {
    .typewriter {
        width: 90%;
        margin-top: 10px;
    }
  }
  
  .typewriter::before {
    content: "";
    animation: 
      type var(--timePerLine) linear infinite, 
      carriageReturn var(--time) steps(var(--lines)) var(--lines),
      caret 0.5s steps(2) infinite;
    background: var(--bgColor);
    border-left: 2px solid;
    bottom: 0;
    height: 2rem;
    position: absolute;
    right: 0;
    width: 100%;
  }
  
  .typewriter.no-caret::before {
    border-left-width: 0;
  }
  
  .typewriter.big-caret::before {
    border-left-width: 1ch;
  }
  
  .typewriter.monospace {
    font-family: monospace, monospace;
  }
  
  .typewriter.monospace::before {
    animation: 
      type var(--timePerLine) steps(var(--widthCh)) infinite, 
      carriageReturn var(--time) steps(var(--lines)) var(--lines),
      caret 0.5s steps(2) infinite;
  }

  .fade-in-text {
    animation: fadeIn 2s;
    -webkit-animation: fadeIn 2s;
    -moz-animation: fadeIn 2s;
    -o-animation: fadeIn 2s;
    -ms-animation: fadeIn 2s;
  }
  
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-moz-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-webkit-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-o-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-ms-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }